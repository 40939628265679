<template>
  <div>
    <div class="header-title">{{ $route.meta.title }}</div>
    <div class="">
      <div>
        <div class="btn-con" style="float: left">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="drawer = true"
          >
            <span class="next-btn-helper">新增授权</span>
          </button>
        </div>
        <el-input
          v-model="page.search"
          prefix-icon="el-icon-search"
          size="small"
          style="width: 300px"
          placeholder="输入授权主体/客体"
          clearable
          @keyup.enter.native="get_list"
          @clear="get_list"
        />
      </div>
      <el-alert
        style="margin-top:5px"
        type="warning"
        :closable="false"
      >
        <p>主客体的关系：（客体）被授予给/被加入到（主体）。</p>
        <p>举例：</p>
        <p>-（权限）被授予给（用户）。（权限）为客体，（用户）为主体。</p>
        <p>-（用户）被加入到（用户组）。（用户）为客体，（用户组）为主体。</p>
      </el-alert>
      <div class="list-box">
        <Loading :show="loading">
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            :header-cell-style="headerCallStyle"
          >
            <el-table-column label="授权人名称" prop="operator_name" />

            <el-table-column label="授权主体/主体类型">
              <template slot-scope="{ row }">
                {{ row.add_name }}
                <p>{{ row.add_to_type }}</p>
              </template>
            </el-table-column>
            <el-table-column label="授权客体/客体类型">
              <template slot-scope="{ row }">
                {{ row.be_name }}
                <p>{{ row.be_added_type }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="domain_name" label="所属域" />
            <el-table-column prop="remark" label="备注" />
            <el-table-column prop="create_time" label="授权时间" show-overflow-tooltip />
            <el-table-column prop="address" label="操作">
              <template slot-scope="{ row }">
                <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="delGroup(row)"
                >
                  <span class="next-btn-helper">取消授权</span>
                </button>
              </template>
            </el-table-column>
          </el-table>
        </Loading>
        <div style="padding: 20px 10px 10px; font-size: 18px">
          <div style="text-align: right">
            <el-pagination
              :page-size="page.page_num"
              :current-page="page.pid"
              background
              layout="total, prev, pager, next"
              :total="total"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>

      <Drawer
        v-model="drawer"
        title="授权"
        :closable="false"
        draggable
        width="600px"
      >
        <Loading :show="loadingDrawer">
          <div class="drawer__content">
            <el-alert
              type="warning"
              :closable="false"
            >
              <p>主客体的关系：（客体）被授予给/被加入到（主体）。</p>
              <p>举例：</p>
              <p>-（权限）被授予给（用户）。（权限）为客体，（用户）为主体。</p>
              <p>-（用户）被加入到（用户组）。（用户）为客体，（用户组）为主体。</p>
            </el-alert>
            <el-form ref="ruleForm" :model="form" :rules="rules">
              <el-form-item label="授权客体" prop="add_to_type">
                <el-radio-group
                  v-model="form.add_to_type"
                  style="width:100%"
                  @change="change_radio_ba_type"
                >
                  <el-radio :disabled="is_disabled_add_to_type" :label="4">用户组</el-radio>
                  <el-radio :disabled="is_disabled_add_to_type" :label="3">用户</el-radio>
                  <el-radio :disabled="is_disabled_add_to_type" :label="2">角色</el-radio>
                  <el-radio :label="1">权限</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="form.add_to_type>0" prop="select_ids1">
                <template slot="label">
                  <span>
                    <span style="color:red">{{ label_name[form.add_to_type] }}</span> 被授予给/被加入
                  </span>
                </template>
                <el-select
                  v-model="form.select_ids1"
                  filterable
                  multiple
                  placeholder=""
                  style="width: 100%"
                  @change="select_change"
                >
                  <el-option
                    v-for="item in select_list"
                    :key="item.id"
                    :label="item.name+(item.domain_name?'@'+item.domain_name:'')"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="授权主体" prop="add_to_type1">
                <el-radio-group
                  v-model="form.add_to_type1"
                  style="width:100%"
                  :disabled="!form.add_to_type>0"
                  @change="change_radio_ba_type1"
                >
                  <el-radio :label="4" :disabled="!form.add_to_type == 4">用户组</el-radio>
                  <el-radio :label="3" :disabled="form.add_to_type >= 3">用户</el-radio>
                  <el-radio :label="2" :disabled="form.add_to_type >= 2">角色</el-radio>
                  <el-radio :label="5" :disabled="form.add_to_type !=1">secret</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="form.add_to_type1>0" prop="select_ids">
                <template slot="label">
                  <span>
                    被授予给/被加入到  <span style="color:red">{{ label_name[form.add_to_type1] }}</span>
                  </span>
                </template>
                <el-select
                  v-model="form.select_ids"
                  filterable
                  :disabled="!form.add_to_type>0"
                  multiple
                  placeholder=""
                  style="width: 100%"
                  @change="change_transfer"
                >
                  <el-option
                    v-for="item in select_list1"
                    :key="item.id"
                    :label="item.name+(item.domain_name?'@'+item.domain_name:'')"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item :label="'备注'" prop="remark">
                <el-input
                  v-model="form.remark"
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 4}"
                />
              </el-form-item>
            </el-form>
          </div>
        </Loading>
        <div class="panel-footer-drawer custom-panel-footer">
          <div class="btn-con" style="float: left">
            <el-button
              type="button"
              class="next-btn next-medium next-btn-primary"
              style="margin-right: 8px"
              :loading="loading_but"
              @click="add('ruleForm')"
            >
              <span class="next-btn-helper">确定</span>
            </el-button>
            <button
              type="button"
              class="next-btn next-medium next-btn-normal"
              style="margin-right: 8px"
              @click="closeDrawer('ruleForm')"
            >
              <span class="next-btn-helper">取消</span>
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  </div>
</template>

<script>
import { grant_list } from '@/api/permissions'
import { add_permission, del_permission } from '@/api'
import { get_all_group_list, get_all_sub_user_list } from '@/api'
import { get_all_roles } from '@/api/roles'
import { get_all_permissions, get_all_secrets } from '@/api/permissions'

export default {
  data () {
    return {
      loading_but: false,
      loading: false,
      loadingDrawer: false,
      checked: false,
      tableData: [],
      pagesize: 10,
      current: 1,
      total: 0,
      drawer: false,
      form: {
        add_to_type: '',
        add_to_type1: '',
        remark: '',
        select_ids: [],
        select_ids1: []
      },
      rules: {
        add_to_type: [{ required: true, message: '请选择授权客体', trigger: 'change' }],
        add_to_type1: [{ required: true, message: '请选择授权主体', trigger: 'change' }],
        select_ids: [{ required: true, message: '请选择', trigger: 'change' }],
        select_ids1: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      page: { search: '', pid: 1, page_num: 10 },

      select_list: [],
      select_list1: [],

      Ole: [],
      add_to_id: [],
      be_added_id: []
    }
  },
  computed: {
    is_disabled_add_to_type () {
      return !(this.$store.state.userInfo.is_super || this.$store.state.userInfo.user_type == 1)
    },

    headerCallStyle () {
      return {
        background: '#eff3f8',
        color: '#333',
        fontWeight: 400,
        fontSize: '12px'
      }
    },
    label_name () {
      return { 5: 'secret', 4: '用户组', 3: '用户', 2: '角色', 1: '权限' }
    }
  },
  mounted () {
    this.get_list()
  },
  methods: {
    change_transfer (list) {
      this.add_to_id = list
      if (this.form.add_to_type != 3) {
        this.Ole = list.map(() => 2)
      }
    },
    select_change (list) {
      this.be_added_id = list

      if (this.form.add_to_type == 3) {
        this.Ole = list.map(() => 2)
      }
    },

    // 新增授权
    add (formName) {
      this.$refs[formName].validate((valid) => {
        let postData = {}
        if (this.form.add_to_type == 4) {
          postData = {
            client_id: 1,
            be_added_type: this.form.add_to_type,
            be_added_id: this.be_added_id,
            add_to_type: this.form.add_to_type1,
            add_to_id: this.add_to_id,
            user_type: this.Ole,
            remark: this.form.remark
          }
        } else if (this.form.add_to_type == 3) {
          postData = {
            client_id: 1,
            be_added_type: this.form.add_to_type,
            be_added_id: this.be_added_id,
            add_to_type: this.form.add_to_type1,
            add_to_id: this.add_to_id,
            user_type: this.Ole,
            remark: this.form.remark
          }
        } else if (this.form.add_to_type == 2) {
          postData = {
            client_id: 1,
            be_added_type: this.form.add_to_type,
            be_added_id: this.be_added_id,
            add_to_type: this.form.add_to_type1,
            add_to_id: this.add_to_id,
            user_type: this.Ole,
            remark: this.form.remark
          }
        } else {
          postData = {
            client_id: 1,
            be_added_type: this.form.add_to_type,
            be_added_id: this.be_added_id,
            add_to_type: this.form.add_to_type1,
            add_to_id: this.add_to_id,
            user_type: this.Ole,
            remark: this.form.remark
          }
        }
        if (valid) {
          this.loading_but = true
          add_permission(postData).then(res => {
            this.loading_but = false
            if (res.code === 0) {
              this.$message({
                showClose: true,
                message: res.msg,
                type: 'success'
              })
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: 'error'
              })
            }
            this.drawer = false
            this.get_list()
            this.$refs[formName].resetFields()
          }).catch(() => {
            this.loading_but = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    closeDrawer (formName) {
      this.drawer = false
      this.$refs[formName].resetFields()
    },

    async get_list () {
      try {
        this.loading = true
        const { data: { data, all_num }} = await grant_list(this.page)
        this.tableData = data
        this.total = all_num
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    // 切换客体
    change_radio_ba_type (val) {
      this.get_transfer_list(val).then(res => {
        this.select_list = res
      })
      this.form.add_to_type1 = ''
      this.form.select_ids1 = []
    },

    // 切换主体
    change_radio_ba_type1 (val) {
      this.form.select_ids = []
      this.get_transfer_list(val).then(res => {
        this.select_list1 = res
      })
    },

    get_transfer_list (type) {
      console.log(type)

      this.loadingDrawer = true
      return new Promise((resolve, reject) => {
        if (type == 5) {
          // 获取权限
          const params = {
            client_uuid: this.$config.client_uuid
          }
          get_all_secrets(params).then(res => {
            this.loadingDrawer = false
            resolve(res.data)
          }).catch(error => {
            this.loadingDrawer = false
            reject(error)
          })
        } else if (type == 4) {
        // 获取用户组
          get_all_group_list(this.search).then(res => {
            this.loadingDrawer = false
            resolve(res.data)
          }).catch(error => {
            this.loadingDrawer = false
            reject(error)
          })
        } else if (type == 3) {
        // 获取用户
          get_all_sub_user_list(this.search).then(res => {
            this.loadingDrawer = false
            resolve(res.data)
          }).catch(error => {
            this.loadingDrawer = false
            reject(error)
          })
        } else if (type == 2) {
        // 获取角色
          const params = {
            is_get_all: true,
            search: this.search
          }
          get_all_roles(params).then(res => {
            this.loadingDrawer = false
            resolve(res.data)
          }).catch(error => {
            this.loadingDrawer = false
            reject(error)
          })
        } else {
          // 获取权限
          const params = {
            is_get_all: true,
            search: this.search
          }
          get_all_permissions(params).then(res => {
            this.loadingDrawer = false
            resolve(res.data)
          }).catch(error => {
            this.loadingDrawer = false
            reject(error)
          })
        }
      })
    },

    // 删除授权
    delGroup (row) {
      this.$confirm('确认取消授权吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            const data = await del_permission({
              client_id: 1,
              grant_id: row.id
            })
            this.$message({
              showClose: true,
              message: data.msg,
              type: 'success'
            })
            this.get_list()
            done()
            instance.confirmButtonLoading = false
          } else {
            done()
          }
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消~'
        })
      })
    },
    handleCurrentChange (val) {
      this.page.pid = val
      this.get_list()
    }
  }
}
</script>

<style>
</style>
